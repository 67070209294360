import React from "react";

import { ChevronRightIcon, HomeIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/navigation";

const Breadcrumbs = ({ breadcrumbs }: any) => {
  const router = useRouter();

  // Function to insert spaces before capital letters
  const formatBreadcrumbName = (name: string) => {
    return name.replace(/([A-Z])/g, " $1").trim();
  };

  return (
    <nav className="float-left mt-2" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <a
              onClick={(e) => router.push("/home")}
              className="text-black hover:text-gray-500"
            >
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>

        {breadcrumbs.map((breadcrumb: any) => (
          <li key={breadcrumb.href}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-black "
                aria-hidden="true"
              />
              <a
                href={breadcrumb.href}
                onClick={(e) => {
                  e.preventDefault();
                  router.push(breadcrumb.href);
                }}
                className={`ml-4 text-sm font-medium ${
                  breadcrumb.isCurrent
                    ? "text-gray-500 "
                    : "text-black hover:text-gray-700 "
                }`}
              >
                {formatBreadcrumbName(breadcrumb.name)}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
